/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:840323cc-d5bb-41e3-9a02-5299c73e6a08",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_fvnBnXWN8",
    "aws_user_pools_web_client_id": "1bf8iuvfa965hvskcjj4446aee",
    "oauth": {},
    "aws_cognito_login_mechanisms": [
        "EMAIL"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cloud_logic_custom": [
        {
            "name": "stripeHook",
            "endpoint": "https://4ycgofqdcb.execute-api.us-east-1.amazonaws.com/main",
            "region": "us-east-1"
        },
        {
            "name": "stripePortal",
            "endpoint": "https://91pjhfsda5.execute-api.us-east-1.amazonaws.com/main",
            "region": "us-east-1"
        },
        {
            "name": "lookup",
            "endpoint": "https://qkw10lr1ge.execute-api.us-east-1.amazonaws.com/main",
            "region": "us-east-1"
        },
        {
            "name": "locationSEO",
            "endpoint": "https://ppw0c6ss56.execute-api.us-east-1.amazonaws.com/main",
            "region": "us-east-1"
        },
        {
            "name": "stripePortalLocal",
            "endpoint": "https://ti4fial41b.execute-api.us-east-1.amazonaws.com/main",
            "region": "us-east-1"
        },
        {
            "name": "listSEO",
            "endpoint": "https://d1l98965q8.execute-api.us-east-1.amazonaws.com/main",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://gx2lrnwsvnczxnmo552gj5gmom.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
